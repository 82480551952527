import { AppProps } from "next/app";
import React, { useEffect } from "react";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

import "../../public/assets/css/tailwind.css";
import "../styles/globals.css";
import { Router } from "next/router";
import TranslationProvider from "@/TranslationProvider";
import { registerServiceWorker } from "@/utils/RegisterServiceWorker";

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

function App({ Component, pageProps, router }: AppProps) {
  const translations = require(`../locales/${router.locale}/common.json`);

  useEffect(() => {
    registerServiceWorker();
  }, []);

  return (
    <TranslationProvider translations={translations}>
      <Component {...pageProps} />
    </TranslationProvider>
  );
}

export default App;
